const Photo = ({ photo }) => {
  const imageData = "https://edusphere.co.in/static/"+photo;
  return (
    <>
      {imageData && (
        <img src={imageData} alt={photo} width="70px" height="70px" />
      )}
    </>
  );
};

export default Photo;
