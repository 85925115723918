import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import "./Style.css";

const Allorder = () => {
    const [purchase, setpurchase] = useState();
    let nav = useNavigate();
    let location = useLocation();
    useEffect(() => {
        axios.post("https://edusphere.co.in/bed/viewpurchase").then((res) => {
            setpurchase(res.data);
        });
    },[]);
    return ( 
        <div className="bgg" style={{minWidth: "100%"}}>
            <Navbar className="navi"> 
                <div className="comname">Hari Beds</div>
                <div style={{width: "10vh"}}></div>
                {window.localStorage.getItem("role") === "retailer" ? 
                    <Nav className="navbar">
                        <button type="button" className="btn me-2 mt-2" id="bot">{location.state.name}</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})}}>Cart({location.state.num})</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/allorder", {state: {name: location.state.name, num: location.state.num}})}}>All Orders</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("retailerid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                    </Nav> : window.localStorage.getItem("role") === "user" ? 
                    <Nav className="navbar">
                        <button type="button" className="btn me-2 mt-2" id="bot">{location.state.name}</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})}}>Cart({location.state.num})</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/allorder", {state: {name: location.state.name, num: location.state.num}})}}>All Orders</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("userid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                    </Nav> : <></>
                }
            </Navbar>
            <div className="mt-2">
                <table className="table" id="tan">
                    <thead>
                        <tr>
                            <th>Bill Number</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Verify</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchase&& purchase.map((c) => {
                            return(c[1].split("")[0] === window.localStorage.getItem("role").split("")[0] ? <>
                                <tr>
                                    <td>{c[0]}</td>
                                    <td>{c[2]}</td>
                                    <td>{c[3]}</td>
                                    <td><button type="button" className="btn" id="bot" onClick={(e) => {nav("/retailerorder", {state: {name: location.state.name, num: location.state.num, id: c[0]}})}}>View Details</button></td>
                                </tr>
                            </> : <></>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
 
export default Allorder;