import { useLocation, useNavigate } from "react-router-dom";
import "./Style.css";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import axios from "axios";
import { useEffect, useState } from "react";

const ProductDetails = () => {
    let location = useLocation();
    let nav = useNavigate();
    const [cap, setcap] = useState([]);
    const [cap1, setcap1] = useState([]);
    useEffect(() => {
        axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: window.localStorage.getItem("retailerid")}).then((res) => {
            let na = res.data;
            location.state.num = na.length;
            na&& na.map((c) => {
                setcap([...cap, c[2]]);
                return cap;
            })
        });
        axios.post("https://edusphere.co.in/bed/viewuser_cart", {user_id: window.localStorage.getItem("userid")}).then((res) => {
            let nu = res.data;
            location.state.num = nu.length;
            nu&& nu.map((c) => {
                setcap1([...cap1, c[2]]);
                return cap1;
            })
        })
    },[])
    const Addedcart = (e, g) => {
        let bay = 0;
        cap.map((c) => {
            if(c === e[3]) {
                bay += 1;
                if(g !== "buy"){
                    alert("Same Product already added to cart !!")
                }
            }
            return bay;
        })
        if(bay === 0) {
            const val2 = {
                retailerid: window.localStorage.getItem("retailerid"),
                prod_siz_col_id: e[3],
                quantity: 1,
            }
            axios.post("https://edusphere.co.in/bed/insertretailerscart", val2).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: window.localStorage.getItem("retailerid")}).then((res) => {
                    let na = res.data;
                    location.state.num = res.data.length;
                    na&& na.map((c) => {
                        setcap([...cap, c[2]]);
                        return cap;
                    })
                    if(g === "buy") {
                        setcap(cap);
                        nav("/retailercart", {state: {name: location.state.name, num: location.state.num}});
                    }
                });
            })
        }
    }
    const Addedcart1 = (e, f) => {
        let bay1 = 0;
        cap1.map((c) => {
            if(c === e[3]) {
                bay1 += 1;
                if(f !== "buy") {
                    alert("Same Product already added to cart !!");
                }
            }
            return bay1;
        })
        if(bay1 === 0) {
            const val3 = {
                user_id: window.localStorage.getItem("userid"),
                prod_siz_col_id: e[3],
                quantity: 1,
            };
            axios.post("https://edusphere.co.in/bed/insertuser_cart", val3).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewuser_cart", {user_id: window.localStorage.getItem("userid")}).then((res) => {
                    let nu = res.data;
                    location.state.num = nu.length;
                    nu&& nu.map((c) => {
                        setcap1([...cap1, c[2]]);
                        return cap1;
                    });
                    if(f === "buy") {
                        nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})
                    }
                });
            });
        }
    };
    const logoutfn = (e) => {
        window.localStorage.removeItem("adminid");
        window.localStorage.setItem("role", "guest")
        nav("/")
    }
    return ( 
        <div className="bgg">
            <Navbar className="navi">
                <div className="comname" style={{fontFamily: 'Autour One', fontSize: '32px', fontStyle: "oblique", fontWeight: "bold"}}>Hari Beds</div>
                <div style={{width: "10vh"}}></div>
                {window.localStorage.getItem("role") === "retailer" ? 
                    <Nav className="navbar">
                        <button type="button" className="btn me-2 mt-2" id="bot">{location.state.name}</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})}}>Cart({location.state.num})</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailerorder", {state: {name: location.state.name, num: location.state.num}})}}>All Orders</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("retailerid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                    </Nav> : window.localStorage.getItem("role") === "user" ? 
                    <Nav className="navbar">
                        <button type="button" className="btn me-2 mt-2" id="bot">{location.state.name}</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})}}>Cart({location.state.num})</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailerorder", {state: {name: location.state.name, num: location.state.num}})}}>All Orders</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("userid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                    </Nav> : window.localStorage.getItem("role") === "admin" ? 
                    <Nav className="navbar">
                        <button type="button" className="btn me-2 mt-2" id="bot">Admin</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/adminpage")}}>Home</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/admin")}}>Add Products</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/viewprod")}}>View Products</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/viewprod")}}>Back</button>
                        <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {logoutfn(e)}}>Logout</button>
                    </Nav> : <></>
                }
            </Navbar>
            <div className="container">
                <h2>Product Details</h2>
                <div id="bige">
                    <div style={{minWidth: "40vh", maxWidth: "50vh"}} className="me-2 mt-2">
                        <Slide>
                            {location.state.data[6].map((d) => {
                                return(
                                    <img src={"https://edusphere.co.in/static/"+d[1]} alt="product" style={{width:"100%", height: "50vh"}}></img>
                                )
                            })}
                        </Slide>
                        {window.localStorage.getItem("role") === "retailer" || window.localStorage.getItem("role") === "user" ? 
                            <div className="mt-2 d-flex justify-content-between">
                                <button type="button" className="btn btn-warning" onClick={(e) => {window.localStorage.getItem("role") === "user" ? Addedcart1(location.state.data, "cart") : window.localStorage.getItem("role") === "retailer" ? Addedcart(location.state.data, "cart") : <></>}}>Add to Cart</button>
                                <button type="button" className="btn btn-danger"onClick={(e) => {window.localStorage.getItem("role") === "user" ? Addedcart1(location.state.data, "buy") : window.localStorage.getItem("role") === "retailer" ? Addedcart(location.state.data, "buy") : <></>}}>Buy</button>
                            </div> : 
                            <div className="mt-2">
                                <button type="button" className="btn btn-warning">Add Image</button>
                            </div>
                        }

                    </div>
                    <div className="table mt-2">
                        <table className="table table-bordered" id="bag">
                            <thead>
                                <tr>
                                    <th style={{width: "35vh"}}>Product Specifications</th>
                                    <th style={{width: "35vh"}}>Values</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Product Id</td>
                                    <td>{location.state.data[4]}</td>
                                </tr>
                                <tr>
                                    <td>Product Name</td>
                                    <td>{location.state.data[7][0][2]}</td>
                                </tr>
                                <tr>
                                    <td>Product Color</td>
                                    <td>{location.state.data[9][0][1]}</td>
                                </tr>
                                <tr>
                                    <td>Quantity</td>
                                    <td>{location.state.data[7][0][0]}</td>
                                </tr>
                                <tr>
                                    <td>Size</td>
                                    <td>
                                        Size Name - {location.state.data[8][0][4]}<br/>
                                        Breadth - {location.state.data[8][0][1]}<br/>
                                        Depth - {location.state.data[8][0][2]}<br/>
                                        Length - {location.state.data[8][0][3]}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Common specifications</td>
                                    <td>
                                        {location.state.data[11].map((e) => {
                                            return(<div key={e[0]}>{e[1]}</div>)
                                        })}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Additional specifications</td>
                                    <td>
                                        {location.state.data[10].map((g) => {
                                            return(<div key={g[0]}>{g[1]}</div>)
                                        })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default ProductDetails;