import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import "./Style.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Adminpage = () => {
    let nav = useNavigate();
    let count1 = 0;
    let count2 = 0;
    let count4 = 0;
    const [placed, setplaced] = useState(0);
    const [accept, setaccept] = useState(0);
    const [cancel, setcancel] = useState(0);
    useEffect(() => {
        if(window.localStorage.getItem("adminid") != null) {
            axios.post("https://edusphere.co.in/bed/viewpurchase").then((res) => {
                if(count1 === 0 && count2 === 0 && count4 === 0) {
                res.data.map((c) => {
                    if(c[3] === "placed") {
                        count1 += 1;
                        setplaced(count1);
                    }
                    else if(c[3] === "accepted") {
                        count2 += 1; 
                        setaccept(count2);
                    }
                    else if(c[3] === "cancelled") {
                        count4 += 1;
                        setcancel(count4);
                    }
                })
                }
            })
        }
        else {
            alert("You have been logged out!");
            nav("/");
        }
        
    },[])
    const logoutfn = (e) => {
        window.localStorage.removeItem("adminid");
        window.localStorage.setItem("role", "guest")
        nav("/")
    }
    return ( 
        <div className="bgg">
            <Navbar expand="sm" className="navi">
                <div className="comname">Hari Beds</div>
                <div style={{width: "10vh"}}></div>
                <Nav className="navbar">
                    <button type="button" className="btn me-2 mt-2" id="bot">Admin</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/adminpage")}}>Home</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/admin")}}>Add Products</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/viewprod")}}>View Products</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {logoutfn(e)}}>Logout</button>
                </Nav>
            </Navbar>
            <div>
                <div className="row">
                    <div className="col-sm-2 ht me-2 mt-2" id="bot1" onClick={(e) => {nav("/statusdetails", {state: {status: "placed"}})}}>
                        <h3>Placed Orders: <br /><span>{placed}</span></h3>
                    </div>
                    <div className="col-sm-2 ht me-2 mt-2" id="bot1" onClick={(e) => {nav("/statusdetails", {state: {status: "accepted"}})}}>
                        <h3>Accepted Orders: <br /><span>{accept}</span></h3>
                    </div>
                    <div className="col-sm-2 ht me-2 mt-2" id="bot1" onClick={(e) => {nav("/statusdetails", {state: {status: "cancelled"}})}}>
                        <h3>Cancelled Orders: <br /><span>{cancel}</span></h3>
                    </div>
                    <div className="col-sm-2 ht me-2 mt-2" id="bot1">
                        <h3>Total Orders Placed: <br /><span>0</span></h3>
                    </div>
                    <div className="col-sm-2 ht me-2 mt-2" id="bot1">
                        <h3>Total Purchase Amount: <br /><span>0</span></h3>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Adminpage;