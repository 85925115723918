import { Route, Routes } from "react-router-dom";
import Homepage from "./homepage";
import Adminpage from "./adminpage";
import AdminPage from "./admin";
import Viewprod from "./viewprod";
import ProductDetails from "./prodDetails";
import RetailerCart from "./retailercart";
import AdminOrder from "./adminOrder";
import Retailerorder from "./retailerorder";
import Allorder from "./allorder";
import Statusdetails from "./statusdetails";
import Qrdetails from "./qrdetails";

const App = () => {
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/adminpage" element={<Adminpage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/viewprod" element={<Viewprod />} />
        <Route path="/proddetails" element={<ProductDetails />} />
        <Route path="/retailercart" element={<RetailerCart />} />
        <Route path="/adminorder" element={<AdminOrder />} />
        <Route path="/retailerorder" element={<Retailerorder />} />
        <Route path="/allorder" element={<Allorder />} />
        <Route path="/statusdetails" element={<Statusdetails />} />
        <Route path="/qrdetails" element={<Qrdetails />} />
      </Routes>
    </>
  );
};

export default App;
