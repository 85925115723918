import axios from "axios";
import { useState } from "react";
const Fileupload = (props) => {
  const { prod, size, color, addfile, count } = props;
  const [selectedFiles, setselectedfiles] = useState("");
  const selectFile = (event) => {
    setselectedfiles(event.target.files);
  };
  const upload = (event) => {
    let currentFiles = selectedFiles[0];

    let formData = new FormData();

    formData.append("file", currentFiles);
    formData.append("prod", prod);
    formData.append("size", size);
    formData.append("color", color);
    formData.append("count", count + 1);

    axios
      .post("https://edusphere.co.in/bed/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        addfile(res.data);
        setselectedfiles("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="form-group" style={{ display: "flex" }}>
        <input
          className="form-control me-2"
          name="file"
          style={{ width: "50vh" }}
          onChange={selectFile}
          type="file"
        />
        <button
          className="btn btn-warning"
          disabled={!selectedFiles}
          onClick={upload}
          type="button"
        >
          Upload
        </button>
      </div>
      <div>{console.log(selectedFiles)}</div>
    </>
  );
};

export default Fileupload;
