import axios from "axios";
import { useState, useEffect } from "react";
import Fileupload from "./Fileupload";
import Display from "./Display";
import { Navbar, Nav } from "react-bootstrap";
import { GrFormAdd } from "react-icons/gr";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { AiFillCloseSquare } from "react-icons/ai";
import { Modal, ModalHeader, ModalFooter, ModalBody, ModalTitle } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import "./Style.css";
import { useLocation, useNavigate } from "react-router-dom";

const AdminPage = () => {
  const [one, setone] = useState(0);
  const [prodName, setprodName] = useState();
  const [prodSize, setprodSize] = useState();
  const [color, setcolor] = useState();
  const [common, setcommon] = useState();
  const [com1, setcom1] = useState([]);
  const [psc, setpsc] = useState("");
  const [prodid, setprodid] = useState();
  const [sizeid, setsizeid] = useState();
  const [colorid, setcolorid] = useState();
  const [com, setcom] = useState("");
  const [add, setadd] = useState("");
  const [prod, setprod] = useState("");
  const [size, setsize] = useState("");
  const [color1, setcolor1] = useState("");
  const [commonid, setcommonid] = useState([]);
  const [addition, setaddition] = useState([]);
  const [edit, setedit] = useState(0);
  const nav = useNavigate();
  const location = useLocation();
  const [divi, setdivi] = useState("");
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  const [show4, setshow4] = useState(false);
  const [newprod1, setnewprod1] = useState("");
  const [newbreadth, setnewbreadth] = useState("");
  const [newdepth, setnewdepth] = useState("");
  const [newlength, setnewlength] = useState("");
  const [sname, setsname] = useState("");
  const [quan, setquan] = useState(0);
  const [colo, setcolo] = useState("");
  const [como, setcomo] = useState("");
  const handle = (e) => {
    e.preventDefault();
    setshow1(false);
    let value1 = {
      product_name: newprod1, 
      quantity: quan,
    }
    axios.post("https://edusphere.co.in/bed/insertproduct", value1).then((res) => {
      alert("New Product added")
      axios.post("https://edusphere.co.in/bed/viewproduct").then((res) => {
        setprodName(res.data);
      });
      setnewprod1("");
      setquan("");
    })
  }
  const handle1 = (e) => {
    e.preventDefault();
    setshow2(false);
    let val1 = {
      breadth: newbreadth,
      depth: newdepth, 
      length: newlength, 
      size_name: sname, 
    }
    axios.post("https://edusphere.co.in/bed/insertsize", val1).then((res) => {
      alert("New Size added")
      axios.post("https://edusphere.co.in/bed/viewsize").then((res) => {
        setprodSize(res.data);
      });
      setnewbreadth("");
      setnewdepth("");
      setnewlength("");
      setsname("");
    })
  }
  const handle2 = (e) => {
    e.preventDefault();
    setshow3(false);
    let val2 = {
      color_name: colo,
    }
    axios.post("https://edusphere.co.in/bed/insertcolor", val2).then((res) => {
      alert("New Color added")
      axios.post("https://edusphere.co.in/bed/viewcolor").then((res) => {
        setcolor(res.data);
      });
      setcolo("")
    });
  }
  const handle3 = (e) => {
    e.preventDefault();
    setshow4(false);
    let val3 = {
      com_spec_text: como,
    }
    axios.post("https://edusphere.co.in/bed/insertcommon_spec", val3).then((res) => {
      alert("New Common Specification Added!")
        axios.post("https://edusphere.co.in/bed/viewcommon_spec").then((res) => {
        setcommon(res.data);
         setcomo("")
      });
     
    })
  }
  const inputt = () => {
    setcommonid([...commonid, com.split(" -")[0]]);
    setcom1([...com1, com]);
    setcom("");
  };
  const hand = (e, g) => {
    const f = com1.filter((c) => {
      return c !== e;
    })
    const i = commonid.filter((d) => {
      return d !== g;
    })
    setcom1(f);
    setcommonid(i);
  }
  const input1 = (k) => {
    setprodid(k.split(" -")[0]);
  };
  const input2 = (k) => {
    setsizeid(k.split("-")[0]);
  };
  const input3 = (k) => {
    setcolorid(k.split(" -")[0]);
  };
  const added = () => {
    setaddition([...addition, add]);
    setadd("");
  };
  const rem = (e) => {
    const j = addition.filter((c) => {
      return c !== e;
    });
    setaddition(j);
  }
  useEffect(() => {
    axios.post("https://edusphere.co.in/bed/viewproduct").then((res) => {
      setprodName(res.data);
    });
    axios.post("https://edusphere.co.in/bed/viewsize").then((res) => {
      setprodSize(res.data);
    });
    axios.post("https://edusphere.co.in/bed/viewcolor").then((res) => {
      setcolor(res.data);
    });
    axios.post("https://edusphere.co.in/bed/viewcommon_spec").then((res) => {
      setcommon(res.data);
    });
    try {
      console.log(location.state.det);
      setone(1);
      setdivi(location.state.det[6]);
      setpsc(location.state.det[0]);
      setprod(
        location.state.det[7][0][0] + " - " + location.state.det[7][0][1]
      );
      setprodid(location.state.det[7][0][0])
      setsizeid(location.state.det[8][0][0])
      setsize(
        location.state.det[8][0][0] +
          "-" +
          location.state.det[8][0][1] +
          "-" +
          location.state.det[8][0][2] +
          "-" +
          location.state.det[8][0][3] +
          "-" +
          location.state.det[8][0][4]
      );
      setcolorid(location.state.det[9][0][0])
      setcolor1(
        location.state.det[9][0][0] + " - " + location.state.det[8][0][1]
      );
      location.state.det[10]&& location.state.det[10].map((c) => {
        return(setaddition([...addition, c[1]]))
      })
      location.state.det[11]&& location.state.det[11].map((c) => {
        setcommonid([...commonid, c[0]])
        let ap = c[0] + " - " + c[1];
        return(setcom1([...com1, ap]))
      })
      location.state.det[6]&& location.state.det[6].map((c) => {
        return(setfile([...file, c[1]]));
      })
      setprice(location.state.det[1]);
      setdiscount(location.state.det[5]);
      setedit(1);
    } catch (err) {
      setedit(0);
    }
  }, []);
  const del = (e) => {
    console.log(e);
    axios
      .post("https://edusphere.co.in/bed/deletefile", { file: e })
      .then((res) => {
        const f = file.filter((c) => {
          return c !== e;
        });
        setfile(f);
      });
  };
  const vals = (e) => {
    e.preventDefault();
    let value = {
      product_id: prodid,
      size_id: sizeid,
      color_id: colorid,
      common_specifications: commonid,
      additional_specifications: addition,
      photo: file,
      price: price,
      userdiscount: discount,
    };
    console.log(value);

    if (edit === 0) {
      axios
        .post("https://edusphere.co.in/bed/insertprod_siz_col", value)
        .then((res) => {
          console.log(res.data);
          setprod("");
          setsize("");
          setcolor1("");
          setdiscount("");
          setprice("");
          setcommonid([]);
          setaddition([]);
          nav("/adminpage");
        })
        .catch((err) => {
          console.log(err);
        });
    } 
    else {
      value.prod_siz_col_id = psc;
      axios
        .post("https://edusphere.co.in/bed/updateprod_siz_col", value)
        .then((res) => {
            // console.log(res.data);
          //   setprod("");
          //   setsize("");
          //   setcolor1("");
          //   setdiscount("");
          //   setprice("");
          //   setcommonid([]);
          //   setaddition([]);
          //   nav("/adminpage");
        })
        .catch((err) => {
          console.log(err);
        });
    }
    nav("/adminpage");
  };
  const [discount, setdiscount] = useState("");
  const [price, setprice] = useState("");
  const [file, setfile] = useState([]);
  const addfile = (f) => {
    console.log(f)
    setfile([...file, f]);
  };
  const logoutfn = (e) => {
    window.localStorage.removeItem("adminid");
    window.localStorage.setItem("role", "guest");
    nav("/")
  }
  return (
    <div className="bgg">
      <Navbar expand="sm" className="navi">
        <div className="comname" style={{fontFamily: 'Autour One', fontSize: '32px', fontStyle: "oblique", fontWeight: "bold"}}>Hari Beds</div>
        <div style={{width: "10vh"}}></div>
        <Nav className="navbar">
          <button type="button" className="btn me-2 mt-2" id="bot">Admin</button>
          <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/adminpage")}}>Home</button>
          <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/admin")}}>Add Products</button>
          <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/viewprod")}}>View Products</button>
          <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {logoutfn(e)}}>Logout</button>
        </Nav>
      </Navbar>
      <div className="container">
        <div className="pt-5">
          <form onSubmit={(e) => { vals(e) }}>
            <div className="row">
              <div className="col-sm-4">
                <label htmlFor="product_name">Product Name</label><br />
                <div style={{display: "flex", alignItems: "center"}}>
                  <input
                    list="name"
                    name="product_name"
                    id="product_name"
                    value={prod}
                    onChange={(e) => {
                      setprod(e.target.value);
                      input1(e.target.value);
                    }}
                  /> <button type="button" className="p-0 ms-1" style={{backgroundColor: "rgba(175, 106, 65, 0.811)"}} onClick={(e) => {setshow1(true)}}><GrFormAdd size={"24px"}/></button>
                </div>
                <br />
                <datalist id="name">
                  {prodName &&
                    prodName.map((c) => {
                      let ca = c[1] + " - " + c[2];
                      return <option key={c[1]} value={ca} />;
                    })}
                </datalist>
              </div>
              <div className="col-sm-4">
                <label htmlFor="product_size">Product Size</label>
                <br />
                <div style={{display: "flex", alignItems: "center"}}>
                  <input
                    list="size"
                    name="product_size"
                    id="product_size"
                    value={size}
                    onChange={(e) => {
                      setsize(e.target.value);
                      input2(e.target.value);
                    }}
                  />
                  <button type="button" className="p-0 ms-1" style={{backgroundColor: "rgba(175, 106, 65, 0.811)"}} onClick={(e) => {setshow2(true)}}><GrFormAdd size={"24px"}/></button>
                </div>
                <br />
                <datalist id="size">
                  {prodSize &&
                    prodSize.map((c) => {
                      let ce =
                        c[3] + "-" + c[0] + "-" + c[1] + "-" + c[2] + "-" + c[4];
                      return <option key={c[3]} value={ce} />;
                    })}
                </datalist>
              </div>
              <div className="col-sm-4">
                <label htmlFor="product_color">Product Color</label>
                <br />
                <div style={{display: "flex", alignItems: "center"}}>
                  <input
                    list="color"
                    name="product_color"
                    id="product_color"
                    value={color1}
                    onChange={(e) => {
                      setcolor1(e.target.value);
                      input3(e.target.value);
                    }}
                  />
                  <button type="button" className="p-0 ms-1" style={{backgroundColor: "rgba(175, 106, 65, 0.811)"}} onClick={(e) => {setshow3(true)}}><GrFormAdd size={"24px"}/></button>
                </div>
                <br />
                <datalist id="color">
                  {color &&
                    color.map((c) => {
                      let cb = c[0] + " - " + c[1];
                      return <option key={c[0]} value={cb} />;
                    })}
                </datalist>
              </div>
            </div>
            <div className="mt-5 row">
              <div className="col-sm-4">
                <label htmlFor="common_spec">Common Specifications</label>
                <br />
                <div style={{display: "flex", alignItems: "center"}}>
                  <input
                    list="common"
                    name="common_spec"
                    value={com}
                    id="common_spec"
                    onChange={(e) => {
                      setcom(e.target.value);
                    }}
                  />
                  <button type="button" className="p-0" style={{border: "1px solid grey", borderRadius: "5px"}} onClick={(e) => {inputt(com)}} ><BsFillArrowRightSquareFill size={"29px"} color="rgba(175, 106, 65, 0.711)"/></button>
                  <button type="button" className="p-0 ms-1" style={{backgroundColor: "rgba(175, 106, 65, 0.811)"}} onClick={(e) => {setshow4(true)}}><GrFormAdd size={"24px"}/></button>
                </div>
                <br />
                <datalist id="common">
                  {common &&
                    common.map((c) => {
                      let cc = c[0] + " - " + c[1];
                      return <option key={c[0]} value={cc} />;
                    })}
                </datalist>
              </div>
              <div  className="col-sm-4 ms-2" style={{height: "10vh", border: "2px solid grey", width: "40vh"}}>
                {com1.map((c) => {
                  return(
                    <div key={c} onClick={(e) => {hand(c, c.split(" -")[0])}}>{c}<AiFillCloseSquare size={17} color="rgba(175, 106, 65, 0.711)"/></div>
                  )
                })}
              </div>
              <div className="row mt-4">
                <div className="col-sm-4 mt-2">
                  <label htmlFor="additional_spec">Additional Specifications</label>
                  <br />
                  <div style={{display: "flex", alignItems: "center"}}>
                    <input
                      type="text"
                      id="additional"
                      value={add}
                      onChange={(e) => {
                        setadd(e.target.value);
                      }}
                    />
                    <button type="button" className="p-0" style={{border: "1px solid grey", borderRadius: "5px"}} onClick={(e) => {added(e)}}><BsFillArrowRightSquareFill size={"29px"} color="rgba(175, 106, 65, 0.711)"/></button>
                  </div>
                </div>
                <div className="col-sm-4 mt-2 ms-3" style={{height: "10vh", border: "2px solid grey", width: "40vh"}}>
                  {addition.map((c) => {
                    return <div key={c} onClick={(e) => {rem(c)}}>{c}<AiFillCloseSquare size={17} color="rgba(175, 106, 65, 0.711)"/></div>;
                  })}
                </div>
              </div>
            </div>
            <div className="mt-4 row">
              <div className="col-sm-4 mt-2">
                <label htmlFor="discount" className="me-2">Discount </label>
                <div>
                  <input
                    type="text"
                    placeholder="discount"
                    value={discount}
                    onChange={(e) => setdiscount(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-4 mt-2">
                <label htmlFor="price" className="me-2">Price </label>
                <div>
                  <input
                    type="text"
                    placeholder="price"
                    value={price}
                    onChange={(e) => setprice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              {prodid && sizeid && colorid && (
                <Fileupload
                  addfile={addfile}
                  prod={prodid}
                  size={sizeid}
                  color={colorid}
                  count={file.length}
                />
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button type="submit" className="mt-5 btn btn-primary">
                Submit
              </button>
              <button
                type="button"
                className="mt-5 btn btn-secondary"
                onClick={(e) => {one === 1 ? nav("/viewprod") : nav("/adminpage") }}
              >
                Cancel
              </button>
            </div>
          </form>
          <Display data={file} del={del} one={one} pot={divi} />
        </div>
        <Modal show={show1}>
          <ModalHeader closeButton>
            <ModalTitle>Add New Product</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <form onSubmit={(e) => {handle(e)}}>
                <label htmlFor="newprod">Product Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setnewprod1(e.target.value)}
                  value={newprod1}
                  placeholder="Enter Product Name"
                />
                <label htmlFor="quantity">Quantity</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setquan(e.target.value)}
                  value={quan}
                  placeholder="Enter Quantity"
                />
                <Button variant="primary" type="submit" className="mt-2">
                  Submit
                </Button>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setshow1(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal show={show2}>
          <ModalHeader closeButton>
            <ModalTitle>Add New Size</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <form onSubmit={(e) => {handle1(e)}}>
                <label htmlFor="newbreadth">Breadth</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setnewbreadth(e.target.value)}
                  value={newbreadth}
                  placeholder="Enter Breadth"
                />
                <label htmlFor="newdepth">Depth</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setnewdepth(e.target.value)}
                  value={newdepth}
                  placeholder="Enter Depth"
                />
                <label htmlFor="newlength">Length</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setnewlength(e.target.value)}
                  value={newlength}
                  placeholder="Enter Length"
                />
                <label htmlFor="newsizename">Size Name</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setsname(e.target.value)}
                  value={sname}
                  placeholder="Enter Size Name"
                />
                <Button variant="primary" type="submit" className="mt-2">
                  Submit
                </Button>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setshow2(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal show={show3}>
          <ModalHeader closeButton>
            <ModalTitle>Add New Color</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <form onSubmit={(e) => {handle2(e)}}>
                <label htmlFor="newprod">Color</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setcolo(e.target.value)}
                  value={colo}
                  placeholder="Enter Color"
                />
                <Button variant="primary" type="submit" className="mt-2">
                  Submit
                </Button>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setshow3(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal show={show4}>
          <ModalHeader closeButton>
            <ModalTitle>Add New Common Specifications</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <form onSubmit={(e) => {handle3(e)}}>
                <label htmlFor="newcom">Common Specifications</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setcomo(e.target.value)}
                  value={como}
                  placeholder="Enter Common Specification"
                />
                <Button variant="primary" type="submit" className="mt-2">
                  Submit
                </Button>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setshow4(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default AdminPage;
