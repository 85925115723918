import Photo from "./Photo";
import { AiFillCloseSquare } from "react-icons/ai";
const Display = ({ data, del }) => {
  return (
    <>
      {data &&
        data.map((d) => {
          return (
            <div key={d} className="row mt-5 d-flex">
              <p style={{position: "relative"}}>{<Photo photo={d}/>}</p><span style={{position: "absolute"}} onClick={(e) => del(d)}><AiFillCloseSquare size={25} color="rgba(175, 106, 65, 1)"/></span>
            </div>
          );
        })}
    </>
  );
};

export default Display;
