import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import "./Style.css";
import ReactToPrint from "react-to-print";

const AdminOrder = () => {
    let nav = useNavigate();
    let location = useLocation();
    const [prod2, setprod2] = useState();
    const [purchasedetails, setpurchasedetails] = useState();
    const [users, setusers] = useState();
    const [retailers, setretailers] = useState();
    let status = location.state.status;
    const [war, setwar] = useState();
    let car = "";
    const [value, setvalue] = useState();
    let cd = 0;
    let ca = [];
    let dd = [];
    useEffect(() => {
        if(window.localStorage.getItem("role") === "admin") {
            axios.post("https://edusphere.co.in/bed/viewprod_siz_col").then((res) => {
                setprod2(res.data);
            });
            axios.post("https://edusphere.co.in/bed/viewpurchase").then((res) => {
                res.data.map((c) => {
                    if(c[0] === location.state.val) {
                        setvalue(c);
                    }
                    return value;
                });
            });
            axios.post("https://edusphere.co.in/bed/viewpurchasedetails").then((res) => {
                setpurchasedetails(res.data);
            });
            axios.post("https://edusphere.co.in/bed/viewusers").then((res) => {
                setusers(res.data);
            });
            axios.post("https://edusphere.co.in/bed/viewretailers").then((res) => {
                setretailers(res.data);
            });
            axios.post("https://edusphere.co.in/bed/viewproductwarrenty").then((res) => {
                setwar(res.data);
            })
        }
        else {
            alert("You have been logged out!");
            nav("/");
        }
    },[]);
    const Buy = (c,e) => {
        axios.post("https://edusphere.co.in/bed/insertproductwarrenty", {data:c, role: e}).then((res) => {
            alert("order accepted")
            nav("/adminpage");
        });
        
    }
    const buy1 = (a) => {
        let val = {
            status: "cancelled",
            purchaseid: a,
        };
        axios.post("https://edusphere.co.in/bed/updatestatus", val).then((res) => {
            alert("Order Rejected");
            nav("/adminpage");
        });
    };
    return ( 
        <div className="bgg" style={{minWidth: "100%"}}>
            <Navbar className="navi"> 
                <div className="comname">Hari Beds</div>
                <div style={{width: "10vh"}}></div>
                <Nav className="navbar">
                    <button type="button" className="btn me-2 mt-2" id="bot">Admin</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/adminpage")}}>Home</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/admin")}}>Add Products</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/viewprod")}}>View Products</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/statusdetails", {state: {status: location.state.status}})}}>Back</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("adminid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                </Nav>
            </Navbar>
            <div className="row mt-2">
                <div className="col-sm-8 card" id="car">
                    <div className="card-body">
                        <div className="row d-flex">
                            {purchasedetails&& purchasedetails.map((d) => {
                                if(value&& value[0] === d[1]) {
                                    ca.push([d[1],d[2],d[3]]);
                                }
                                return(value&& value[0] === d[1] ?
                                    <div className="mt-2 col-sm-5">
                                        {prod2&& prod2.map((c) => {
                                            return(c[3] === d[2] ? 
                                                <div key={d[0]} className="card" id="car">
                                                    <div className="card-body">
                                                        <div className="row d-flex">
                                                            <div className="col-sm">
                                                                <img key={c[6][0]} src={"https://edusphere.co.in/static/"+c[6][0][1]} alt="Card" style={{width:"25vh", height: "25vh"}}></img>
                                                            </div>
                                                            <div className="col-sm">
                                                                <h4>{c[7][0][2]}</h4>
                                                                <span>Color: {c[9][0][1]}</span><br/>
                                                                <span>Breadth: {c[8][0][0]}</span><br/>
                                                                <span>Depth: {c[8][0][1]}</span><br/>
                                                                <span>Length: {c[8][0][2]}</span><br />
                                                                <span>Quantity: <b>{d[3]}</b></span><br />
                                                                <span>Price: <b>{d[4]}</b>({c[1]}% off)</span>
                                                            </div>
                                                        </div>
                                                        {status&& (status === "accepted") ? <div><button type="button" className="btn" id="bot" style={{width: "100%"}} onClick={(e) => {nav("/qrdetails", {state: {prod: c, det: d, status: status}})}}>View QR</button></div> : <></>}
                                                    </div>
                                                </div>
                                            : <div></div>)
                                        })}
                                    </div>
                                : <></>)
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    {cd === 0 ? 
                        <div className="mt-2">
                            <div className="card mx-2" id="car">
                                <div className="card-body">
                                    <h5>Ordered By</h5>
                                    <table className="table" id="tan">
                                        <tbody>
                                            {value&& value[1].split("")[0] === "u" ? users&& users.map((f) => {
                                                cd = 1;
                                                return(value[1] === f[3] ? <>
                                                    <tr>
                                                        <td><h6>User</h6></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>{f[4]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Id</td>
                                                        <td>{f[3]}</td>
                                                    </tr>
                                                </> : <></>)
                                            }) : value&& value[1].split("")[0] === "r" ? retailers&& retailers.map((s) => {
                                                cd = 1;
                                                return(value[1] === s[4] ? <>
                                                    <tr>
                                                        <td><h6>Retailer</h6></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Name</td>
                                                        <td>{s[5]}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Id</td>
                                                        <td>{s[4]}</td>
                                                    </tr>
                                                </> : <></>)
                                            }) : <></>}
                                        </tbody>
                                    </table>
                                    {status&& status === "accepted" ? 
                                        <div className="mt-2">
                                            <ReactToPrint 
                                                trigger={() => {return <Button type="submit" className="btn" id="bot" style={{width: "100%"}}>Print</Button>}}
                                                content={() => car} 
                                                pageStyle="print" 
                                            />
                                            <div id="qrp" ref={(el) => (car=el)}>
                                                {war&& war.map((c) => {
                                                    return(c[1] === location.state.val ? 
                                                        <div className="mt-5">
                                                            <h3>Hari Beds</h3>
                                                            {prod2&& prod2.map((d) => {
                                                                return(d[3] === c[5] ? <>
                                                                    <h6>{d[7][0][2]}</h6>
                                                                    <p><span>Size : {d[8][0][2]}x{d[8][0][0]}x{d[8][0][1]}&#8221;</span><br/>
                                                                        <span>Purchased on</span><br/><span>Warrenty ends on</span>
                                                                    </p>
                                                                </> : <></>)
                                                            })}
                                                            <img src={"https://edusphere.co.in/static/qr/"+c[4]} alt="QR" style={{width:"100px", height: "100px"}}></img>
                                                            <div>_______________________</div>
                                                        </div> : <></>
                                                    )
                                                })}
                                            </div>
                                        </div> : <></>
                                    }
                                </div>
                                {status&& status === "placed" ? <div className="card-footer d-flex justify-content-center">
                                    <div className="col-sm d-flex justify-content-between align-items-center">
                                        <div className="mt-2">
                                            <button type="button" className="btn btn-success" onClick={(e) => {Buy(ca, value[1].split("")[0])}}>Accept</button>
                                        </div>
                                        <div className="mt-2">
                                            <button type="button" className="btn btn-danger" onClick={(e) => {buy1(value[0])}}>Reject</button>
                                        </div>
                                    </div> 
                                </div>: <></>}
                            </div>
                        </div>
                    : <></>}
                </div>
            </div>
        </div>
    );
    
}
 
export default AdminOrder;