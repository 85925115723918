import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { IoAddCircle, IoRemoveCircle } from "react-icons/io5";
import "./Style.css";

const RetailerCart = () => {
    const [cartd, setcartd] = useState([]);
    let fire1 = 1;
    let k = 0;
    const rid = window.localStorage.getItem("retailerid");
    const uid = window.localStorage.getItem("userid");
    let cart3 = [];
    let order = [];
    let nav = useNavigate();
    let location = useLocation();
    const [prod2, setprod2] = useState();
    let tot = 0, cot = 0, pro = 0;
    useEffect(() => {
        axios.post("https://edusphere.co.in/bed/viewprod_siz_col").then((res) => {
            res.data.map((c) => {
                c[12] = 1;
                return c;
            })
            setprod2(res.data);
        });
        if(rid !== null && uid === null) {
            axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: rid}).then((res) => {
                setcartd(res.data);
                location.state.num = res.data.length;
            });
        }
        else if(uid !== null && rid === null) {
            axios.post("https://edusphere.co.in/bed/viewuser_cart", {user_id: uid}).then((res) => {
                setcartd(res.data);
                location.state.num = res.data.length;
            });
        }
    },[]);
    const Addquan = (e) => {
        let f = -1;
        let cid = 0;
        prod2&& prod2.map((c) => {
            f += 1;
            if(c[3] === e) {
                prod2[f][12] += 1;
                fire1 = prod2[f][12];
            }
            setprod2([...prod2]);
            return prod2;
        })
        cartd&& cartd.map((c) => {
            if(c[2] === e) {
                cid = c[0];
            }
            return c;
        })
        if(rid !== null && uid === null) {
            const val3 = {
            retailerid: rid, 
            prod_siz_col_id: e, 
            quantity: fire1, 
            cartid: cid,
            }
            axios.post("https://edusphere.co.in/bed/updateretailerscart", val3).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: rid}).then((res) => {
                    setcartd(res.data);
                });
            })
        }
        else if(uid !== null && rid === null) {
            const val3 = {
            user_id: uid, 
            prod_siz_col_id: e, 
            quantity: fire1, 
            cart_id: cid,
            }
            axios.post("https://edusphere.co.in/bed/updateuser_cart", val3).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewuser_cart", {user_id: uid}).then((res) => {
                    setcartd(res.data);
                });
            })
        }
    }
    const Subquan = (e) => {
        let f = -1;
        let cid = 0;
        prod2&& prod2.map((c) => {
            f += 1;
            if(c[3] === e) {
                if(prod2[f][12] > 1) {
                    prod2[f][12] -= 1;
                    fire1 = prod2[f][12];
                }
                else {
                    alert("Quantity must be greater than 0");
                }
            }
            setprod2([...prod2]);
            return prod2;
        })
        cartd.map((c) => {
            if(c[2] === e) {
                cid = c[0];
            }
            return c;
        })
        if(rid !== null && uid === null) {
            const val3 = {
            retailerid: rid, 
            prod_siz_col_id: e, 
            quantity: fire1, 
            cartid: cid,
            }
            axios.post("https://edusphere.co.in/bed/updateretailerscart", val3).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: rid}).then((res) => {
                    setcartd(res.data);
                });
            })
        }
        else if(uid !== null && rid === null) {
            const val3 = {
            user_id: uid, 
            prod_siz_col_id: e, 
            quantity: fire1, 
            cart_id: cid,
            }
            axios.post("https://edusphere.co.in/bed/updateuser_cart", val3).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewuser_cart", {user_id: uid}).then((res) => {
                    setcartd(res.data);
                });
            })
        }
    }
    const Remove = (e) => {
        if(window.localStorage.getItem("role") === "retailer") {
            let cid = 0;
            cartd.map((c) => {
                if(c[2] === e) {
                    cid = c[0];
                }
                return c;
            })
            axios.post("https://edusphere.co.in/bed/deleteretailerscart", {id: cid}).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: rid}).then((res) => {
                    setcartd(res.data);
                    location.state.num = res.data.length;
                });
            })
        }
        else if(window.localStorage.getItem("role") === "user") {
            let cid = 0;
            cartd.map((c) => {
                if(c[2] === e) {
                    cid = c[0];
                }
                return c;
            })
            axios.post("https://edusphere.co.in/bed/deleteuser_cart", {id: cid}).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewuser_cart", {user_id: uid}).then((res) => {
                    setcartd(res.data);
                    location.state.num = res.data.length;
                });
            })
        }
    }
    const purch = (g) => {
        let idd = "", roli = "";
        if(rid !== null && uid === null) {
            idd = rid;
            roli = "retailer"
        }
        else if(uid !== null && rid === null) {
            idd = uid;
            roli = "user";
        }
        var val = {
            retailersid: idd,
            totalamount: g,
            purchasedetails: order,
            role: roli,
        }
        axios.post("https://edusphere.co.in/bed/insertpurchase", val).then((res) => {
            alert("Order Placed");
            nav("/");
        })

    }
    if(cartd === undefined) {
        return(
            <div>
                Cart is Empty. Click <span style={{color: "lightblue"}} onClick={(e) => {nav("/")}}>here</span> to view products.
            </div>
        )
    }
    else {
        return ( 
            <div className="bgg" style={{minWidth: "100%"}}>
                <Navbar className="navi"> 
                    <div className="comname">Hari Beds</div>
                    <div style={{width: "10vh"}}></div>
                    {window.localStorage.getItem("role") === "retailer" ? 
                        <Nav className="navbar">
                            <button type="button" className="btn me-2 mt-2" id="bot">{location.state.name}</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})}}>Cart({location.state.num})</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/allorder", {state: {name: location.state.name, num: location.state.num}})}}>All Orders</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("retailerid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                        </Nav> : window.localStorage.getItem("role") === "user" ? 
                        <Nav className="navbar">
                            <button type="button" className="btn me-2 mt-2" id="bot">{location.state.name}</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})}}>Cart({location.state.num})</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/allorder", {state: {name: location.state.name, num: location.state.num}})}}>All Orders</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("userid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                        </Nav> : <></>
                    }
                </Navbar>
                <div className="row">
                    <div className="col-sm-8">
                        {prod2&& prod2.map((c) => {
                            cart3 = cartd;
                            return(cart3.map((d) => {
                                if(c[3] === d[2]) {
                                    tot += c[0]*d[3];
                                    cot += (c[0] - (c[0]*c[1]/100))*d[3];
                                    pro = (c[0] - (c[0]*c[1]/100))*d[3];
                                    k = (c[0] - (c[0]*c[1]/100))*d[3]
                                    order.push({prod_siz_col_id: c[3], quantity: d[3], price: pro});
                                }
                                return(c[3] === d[2] ? 
                                    <div className="mt-2">
                                        <div key={c[3]} className="card mx-2 mb-2" id="car">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm d-flex">
                                                        <div className="me-3">
                                                            <img key={c[6][0]} src={"https://edusphere.co.in/static/"+c[6][0][1]} alt="Card" style={{width:"25vh", height: "25vh"}}></img>
                                                        </div>
                                                        <div>
                                                            <h4>{c[7][0][2]}</h4>
                                                            <span>Color: {c[9][0][1]}</span><br/>
                                                            <span>Breadth: {c[8][0][0]}</span><br/>
                                                            <span>Depth: {c[8][0][1]}</span><br/>
                                                            <p>Length: {c[8][0][2]}</p>
                                                            <p>{c[1]}% off</p>
                                                        </div>
                                                    </div>
                                                    <div  className="ms-2 col-sm d-flex justify-content-between align-items-center">
                                                        <div className="mt-2">
                                                            <h5 style={{textAlign: "center"}}>Quantity</h5>
                                                            <div className="d-flex">
                                                                <button type="button" className="btn p-0" onClick={(e) => {Addquan(d[2])}}><IoAddCircle size={"25px"} color="rgb(126, 49, 4)"/></button>
                                                                <button type="button" className="btn py-0" id="bot">{d[3]}</button>
                                                                <button type="button" className="btn p-0" onClick={(e) => {Subquan(d[2])}}><IoRemoveCircle size={"25px"} color="rgb(126, 49, 4)"/></button>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <h5 style={{textAlign: "center"}}>Price</h5>
                                                            <span><del>Rs.{c[0]*d[3]}</del></span>
                                                            <span style={{textAlign: "center"}}> Rs.{k}</span>
                                                        </div>
                                                        <div className="mt-2">
                                                            <button type="button" className="btn" id="bot" onClick={(e) => Remove(d[2])}>Remove</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <div></div>)
                            }))
                        })}
                    </div>
                    <div className="col-sm mt-2">
                        <div className="card mx-2" id="car">
                            <div className="card-body">
                                <h5>Price Details</h5>
                                <div className="mt-2">
                                    <table className="table" id="tan">
                                        <tbody>
                                            <tr>
                                                <td>Price({cartd.length} items)</td>
                                                <td>Rs. {tot}</td>
                                            </tr>
                                            <tr>
                                                <td>Discount</td>
                                                <td>Rs. {tot - cot}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Amount</td>
                                                <td>Rs. {cot}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="button" className="btn d-flex justify-content-center" id="bot" style={{width: "100%", height: "100%"}} onClick={(e) => {purch(cot)}}><h4>Place Order</h4></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
}
 
export default RetailerCart;