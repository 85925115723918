import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import "./Style.css";

const Retailerorder = () => {
    let rall = [];
    let uall = [];
    const [pur, setpur] = useState();
    const role = window.localStorage.getItem("role");
    let nav = useNavigate();
    let location = useLocation();
    const [prod2, setprod2] = useState();
    const [vp, setvp] = useState();
    let tot = 0, cot = 0;
    useEffect(() => {
        axios.post("https://edusphere.co.in/bed/viewprod_siz_col").then((res) => {
            setprod2(res.data);
        });
        axios.post("https://edusphere.co.in/bed/viewpurchase").then((res) => {
            setvp(res.data);
        });
        axios.post("https://edusphere.co.in/bed/viewpurchasedetails").then((res) => {
            setpur(res.data);
        });
    },[]);
    if((uall === null && role === "user") || (rall === null && role === "retailer")) {
        return(
            <div>
                No order History. Click <span style={{color: "lightblue"}} onClick={(e) => {nav("/")}}>here</span> to view products.
            </div>
        )
    }
    else {
        return ( 
            <div className="bgg" style={{minWidth: "100%"}}>
                <Navbar className="navi"> 
                    <div className="comname">Hari Beds</div>
                    <div style={{width: "10vh"}}></div>
                    {window.localStorage.getItem("role") === "retailer" ? 
                        <Nav className="navbar">
                            <button type="button" className="btn me-2 mt-2" id="bot">{location.state.name}</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})}}>Cart({location.state.num})</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/allorder", {state: {name: location.state.name, num: location.state.num}})}}>All Orders</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("retailerid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                        </Nav> : window.localStorage.getItem("role") === "user" ? 
                        <Nav className="navbar">
                            <button type="button" className="btn me-2 mt-2" id="bot">{location.state.name}</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: location.state.name, num: location.state.num}})}}>Cart({location.state.num})</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/allorder", {state: {name: location.state.name, num: location.state.num}})}}>All Orders</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("userid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                        </Nav> : <></>
                    }
                </Navbar>
                <div className="row">
                    <div className="col-sm-8">
                        {prod2&& prod2.map((c) => {
                            return(vp&& vp.map((d) => {
                                if(d[0] === location.state.id) {
                                    return(pur&& pur.map((e) => {
                                        if(e[1] === d[0] && c[3] === e[2]) {
                                            tot += c[0]*e[3];
                                            cot += (c[0] - (c[0]*c[1]/100))*e[3];
                                        }
                                        return(e[1] === d[0] && c[3] === e[2] ? 
                                            <div className="mt-2">
                                                <div key={c[3]} className="card mx-2 mb-2" id="car">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-sm d-flex">
                                                                <div className="me-3">
                                                                    <img key={c[6][0]} src={"https://edusphere.co.in/static/"+c[6][0][1]} alt="Card" style={{width:"25vh", height: "25vh"}}></img>
                                                                </div>
                                                                <div>
                                                                    <h4>{c[7][0][2]}</h4>
                                                                    <span>Color: {c[9][0][1]}</span><br/>
                                                                    <span>Breadth: {c[8][0][0]}</span><br/>
                                                                    <span>Depth: {c[8][0][1]}</span><br/>
                                                                    <p>Length: {c[8][0][2]}</p>
                                                                    <p>{c[1]}% off</p>
                                                                </div>
                                                            </div>
                                                            <div  className="ms-2 col-sm d-flex justify-content-between align-items-center">
                                                                <div className="mt-2">
                                                                    <h5 style={{textAlign: "center"}}>Quantity</h5>
                                                                    <button type="button" className="btn py-0" id="bot">{e[3]}</button>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <h5 style={{textAlign: "center"}}>Price</h5>
                                                                    <span><del>Rs.{c[0]*e[3]}</del></span>
                                                                    <span style={{textAlign: "center"}}> Rs.{(c[0] - (c[0]*c[1]/100))*e[3]}</span>
                                                                </div>
                                                                <div>
                                                                    <h5 style={{textAlign: "center"}}>Status</h5>
                                                                    <span>{d[3]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : <></>
                                        )
                                    }))
                                }
                            }))
                        })}
                    </div>
                    <div className="col-sm mt-2">
                        <div className="card mx-2" id="car">
                            <div className="card-body">
                                <h5>Price Details</h5>
                                <div className="mt-2">
                                    <table className="table" id="tan">
                                        <tbody>
                                            <tr>
                                                <td>Price({role === "retailer" ? rall.length : role === "user" ? uall.length : 0} items)</td>
                                                <td>Rs. {tot}</td>
                                            </tr>
                                            <tr>
                                                <td>Discount</td>
                                                <td>Rs. {tot - cot}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Amount</td>
                                                <td>Rs. {cot}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
}
 
export default Retailerorder;