import axios from "axios";
import { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Style.css";
const Viewprod = () => {
  const [prod, setprod] = useState();
  let nav = useNavigate();
  const peg1 = 0;
  useEffect(() => {
    axios.post("https://edusphere.co.in/bed/viewprod_siz_col").then((res) => {
      setprod(res.data);
    });
  }, []);
  const del = (e) => {
    let d = { id: e };
    axios.post("https://edusphere.co.in/bed/deleteprod_siz_col", d).then((res) => {
      axios.post("https://edusphere.co.in/bed/viewprod_siz_col").then((res) => {
        alert("delete");
        setprod(res.data);
      });
    });
  };
  const logoutfn = (e) => {
    window.localStorage.removeItem("adminid");
    window.localStorage.setItem("role", "guest")
    nav("/")
  }
  return (
    <div className="bgg">
      <Navbar expand="sm" className="navi">
        <div className="comname" style={{fontFamily: 'Autour One', fontSize: '32px', fontStyle: "oblique", fontWeight: "bold"}}>Hari Beds</div>
        <div style={{width: "10vh"}}></div>
        <Nav className="navbar">
          <button type="button" className="btn me-2 mt-2" id="bot">Admin</button>
          <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/adminpage")}}>Home</button>
          <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/admin")}}>Add Products</button>
          <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/viewprod")}}>View Products</button>
          <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {logoutfn(e)}}>Logout</button>
        </Nav>
      </Navbar>
      <div className="container pt-5">
        <h4 className="card-title mb-4">Products</h4>
        <div className="row d-flex">
          {prod &&
            prod.map((c) => {
              return (
                <div key={c[3]} className="card col-3 mx-2 mb-2" id="car" style={{ width: "30vh" }}>
                  <div className="card-body">
                    <div>
                      <Slide>
                        {c[6].map((d) => {
                          return (
                            <img className="card-img-top" src={"https://edusphere.co.in/static/" + d[1]} alt="Card" style={{ width: "100%", height: "25vh" }} onClick={(e) => { nav("/proddetails", { state: { data: c, pag: peg1 }, });}}></img>
                          );
                        })}
                      </Slide>
                      <div>
                        <p>{c[7][0][2]}</p>
                        <p>
                          <del>Rs.{c[0]}</del> Rs.{c[0] - c[0] * (c[1] / 100)}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button type="button" className="btn btn-primary me-2" onClick={(e) => {nav("/admin", { state: { det: c } });}}>Edit</button>
                      <button type="button" className="btn btn-danger" onClick={(e) => { del(c[3]);}}>Delete</button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Viewprod;
