import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { Modal, ModalHeader, ModalFooter, ModalBody, ModalTitle, Dropdown,} from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import "./Style.css";
const Homepage = () => {
    const [prod1, setprod1] = useState();
    const [role2, setrole2] = useState(window.localStorage.getItem("role"));
    const [num, setnum] = useState(0);
    const [cap, setcap] = useState([]);
    const [cap1, setcap1] = useState([]);
    const [show1, setshow1] = useState(false);
    const [show2, setshow2] = useState(false);
    const [show3, setshow3] = useState(false);
    const [show4, setshow4] = useState(false);
    const [show5, setshow5] = useState(false);
    const [id, setid] = useState("");
    const [name, setname] = useState("");
    const [num1, setnum1] = useState(0);
    const [password, setpassword] = useState("");
    const [email, setemail] = useState("");
    const [mobile, setmobile] = useState("");
    const [discount, setdiscount] = useState(0);
    const [rname, setrname] = useState("");
    const [uname, setuname] = useState("");
    let nav = useNavigate();
    let bay = 0;
    const Addcart = (e, g) => {
        cap.map((c) => {
            if(c === e[3]) {
                bay += 1;
                if(g !== "buy") {
                    alert("Same Product already added to cart !!");
                }
            }
            return bay;
        })
        if(bay === 0) {
            const val2 = {
                retailerid: window.localStorage.getItem("retailerid"),
                prod_siz_col_id: e[3],
                quantity: 1,
            }
            axios.post("https://edusphere.co.in/bed/insertretailerscart", val2).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: window.localStorage.getItem("retailerid")}).then((res) => {
                    let na = res.data;    
                    setnum(na.length);
                    na&& na.map((c) => {
                        setcap([...cap, c[2]]);
                        return cap;
                    });
                    if(g === "buy") {
                        nav("/retailercart", {state: {name: rname, num: na.length}})
                    }
                });
            })
        }
    }
    let bay1 = 0;
    const Addcart1 = (e, f) => {
        cap1.map((c) => {
            if(c === e[3]) {
                bay1 += 1;
                if(f !== "buy") {
                    alert("Same Product already added to cart !!");
                }
            }
            return bay1;
        })
        if(bay1 === 0) {
            const val3 = {
                user_id: window.localStorage.getItem("userid"),
                prod_siz_col_id: e[3],
                quantity: 1,
            }
            axios.post("https://edusphere.co.in/bed/insertuser_cart", val3).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewuser_cart", {user_id: window.localStorage.getItem("userid")}).then((res) => {
                    let nu = res.data;
                    setnum1(res.data.length);
                    nu&& nu.map((c) => {
                        setcap1([...cap1, c[2]]);
                        return cap1;
                    });
                    if(f === "buy") {
                        nav("/retailercart", {state: {name: uname, num: nu.length}})
                    }
                });
            });
        }
    }
    useEffect(() => {
        if(role2 === null) {
            window.localStorage.setItem("role", "guest");
        }
        else if(role2 === "admin") {
            window.localStorage.setItem("role", "guest");
            window.localStorage.removeItem("adminid");
        }
        axios.post("https://edusphere.co.in/bed/viewprod_siz_col").then((res) => {
            setprod1(res.data)
        })
        axios.post("https://edusphere.co.in/bed/viewretailers").then((res) => {
            let a = res.data;
            a&& a.map((c) => {
                if(window.localStorage.getItem("retailerid") === c[4]) {
                    setrname(c[5]);
                }
                return rname;
            })
        });
        axios.post("https://edusphere.co.in/bed/viewusers").then((res) => {
            let b = res.data;
            b&& b.map((c) => {
                if(window.localStorage.getItem("userid") === c[3]) {
                    setuname(c[4]);
                }
                return uname;
            })
        });
        if(window.localStorage.getItem("retailerid") !== null) {
            axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: window.localStorage.getItem("retailerid")}).then((res) => {
            let na = res.data;    
            setnum(na.length);
            na&& na.map((c) => {
                setcap([...cap, c[2]]);
                return cap;
            })
            });
        }
        if(window.localStorage.getItem("userid") !== null) {
            axios.post("https://edusphere.co.in/bed/viewuser_cart", {user_id: window.localStorage.getItem("userid")}).then((res) => {
                let nu = res.data;
                setnum1(nu.length);
                nu&& nu.map((c) => {
                    setcap1([...cap1, c[2]]);
                    return cap1;
                })
            });
        }
    },[]);
    const Admin = () => {
    setshow1(true);
    };
    const User = () => {
        setshow2(true);
    };
    const Retailer = () => {
        setshow3(true);
    };
    const NewUser = () => {
        setshow4(true);
    };
    const NewRetailer = () => {
        setshow5(true);
    };
    const handle = (e) => {
        e.preventDefault();
        setshow1(false);
        if (id === "admin1" && password === "admin@1") {
            alert("Admin login Successful !.");
            window.localStorage.setItem("adminid", id);
            window.localStorage.setItem("role", "admin");
            nav("/adminpage");
        }
        setid("");
        setpassword("");
    };
    const handle1 = (e) => {
        e.preventDefault();
        setshow2(false);
        const value = { uid: id, password: password };
        axios.post("https://edusphere.co.in/bed/userlogin", value).then((res) => {
            alert("User login Successful !!..");
            window.localStorage.setItem("userid", id);
            window.localStorage.setItem("role", "user");
            setrole2(window.localStorage.getItem("role"))
            axios.post("https://edusphere.co.in/bed/viewusers").then((res) => {
                let b = res.data;
                b&& b.map((c) => {
                    if(window.localStorage.getItem("userid") === c[3]) {
                        setuname(c[4]);
                    }
                    return uname;
                })
            })
        });
        setid("");
        setpassword("");
    };
    const handle2 = (e) => {
        e.preventDefault();
        setshow3(false);
        const value = { uid: id, password: password };
        axios.post("https://edusphere.co.in/bed/retailerlogin", value).then((res) => {
            alert("Retailer login Successful"); 
            window.localStorage.setItem("retailerid", id);
            window.localStorage.setItem("role", "retailer");
            setrole2(window.localStorage.getItem("role"));
            axios.post("https://edusphere.co.in/bed/viewretailers").then((res) => {
                let a = res.data;
                a&& a.map((c) => {
                    if(window.localStorage.getItem("retailerid") === c[4]) {
                        setrname(c[5]);
                    }
                    return rname;
                })
            })
            if(window.localStorage.getItem("retailerid") !== null) {
                axios.post("https://edusphere.co.in/bed/viewretailerscart", {id: window.localStorage.getItem("retailerid")}).then((res) => {
                let na = res.data;
                setnum(na.length);
                na&& na.map((c) => {
                    setcap([...cap, c[2]]);
                    return cap;
                })
                });
            }
        });
        setid("");
        setpassword("");
    };
    const handle3 = (e) => {
            e.preventDefault();
            setshow4(false);
            const value = { name: name, password: password, email: email, mobile: mobile };
            axios.post("https://edusphere.co.in/bed/insertusers", value).then((res) => {
                axios.post("https://edusphere.co.in/bed/viewusers").then((res) => {
                    let a = res.data;
                    let k = a.length;
                    alert("User Signup Successful!! Your Id is  u" + k);
                });
            });
            
            setname("");
            setpassword("");
            setemail("");
    };
    const handle4 = (e) => {
        e.preventDefault();
        setshow5(false);
        const value = {
            retailername: name,
            password: password,
            mobile: mobile,
            email: email,
            status: "",
            discount: discount,
        };
        axios.post("https://edusphere.co.in/bed/insertretailers", value).then((res) => {
            axios.post("https://edusphere.co.in/bed/viewretailers").then((res) => {
                let a = res.data;
                let k = a.length;
                alert("Retailer Signup Successful!! Your Id is  r" + k);
            });
        });
        setname("");
        setpassword("");
        setemail("");
        setdiscount("");
    };
    return (
        <div className="bgg">
            <div >
                <Navbar className="navi">
                    <div className="comname">Hari Beds</div>
                    <div style={{width: "10vh"}}></div>
                    {role2 === "user" ? 
                       <Nav className="navbar">
                            <button type="button" className="btn me-2 mt-2" id="bot">{uname}</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: uname, num: num1}})}}>Cart({num1})</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/allorder", {state: {name: uname, num: num1}})}}>All Orders</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("userid"); window.localStorage.setItem("role", "guest"); setrole2(window.localStorage.getItem("role"))}}>Logout</button>
                        </Nav> : role2 === "retailer" ?
                        <Nav className="navbar">
                            <button type="button" className="btn me-2 mt-2" id="bot">{rname}</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/")}}>Home</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/retailercart", {state: {name: rname, num: num}})}}>Cart({num})</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/allorder", {state: {name: rname, num: num}})}}>All Orders</button>
                            <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("retailerid"); window.localStorage.setItem("role", "guest"); setrole2(window.localStorage.getItem("role"))}}>Logout</button>
                        </Nav> :
                        <Nav className="navbar">
                            <Dropdown className="mt-2">
                                <Dropdown.Toggle id="dropdown-basic" className="me-2 bot1" style={{backgroundColor: "rgba(175, 106, 65, 0.811)", border: "none"}}>Login</Dropdown.Toggle>
                                <Dropdown.Menu id="drop">
                                    <Dropdown.Item><div onClick={(e) => {Admin(e)}}>Admin</div></Dropdown.Item>
                                    <Dropdown.Item><div onClick={(e) => {User(e)}}>User</div></Dropdown.Item>
                                    <Dropdown.Item><div onClick={(e) => {Retailer(e)}}>Retailer</div></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown className="mt-2">
                                <Dropdown.Toggle id="dropdown-basic" style={{backgroundColor: "rgba(175, 106, 65, 0.811)", border: "none"}} className="me-2 bot1">Signup</Dropdown.Toggle>
                                <Dropdown.Menu id="drop">
                                    <Dropdown.Item><div onClick={(e) => {NewUser(e)}}>New User</div></Dropdown.Item>
                                    <Dropdown.Item><div onClick={(e) => {NewRetailer(e)}}>New Retailer</div></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <button type="button" className="btn me-2 mt-2" id="bot">About</button>
                            <button type="button" className="btn me-2 mt-2" id="bot">Contact Us</button>
                        </Nav>
                    }
                </Navbar>
            </div>
            {/* <div className="comname" style={{fontFamily: 'Autour One', fontSize: '32px', fontStyle: "oblique", fontWeight: "bold"}}>Hari Beds and Co.</div> */}
            <div  className="container mt-5">
                <div className="mb-2" style={{fontFamily: "Audiowide"}}>Welcome {role2 === "user" ? <span>{uname}</span> : role2 === "retailer" ? <span>{rname}</span> : role2 === "guest" || role2 === null ? <span>to Hari Beds</span> : <></>} ! View our latest products with most exciting offers</div>
                <div className="row" style={{display: "flex"}}>
                    {prod1&& prod1.map((c) => {
                        return(
                            <div key={c[0]} className="card col-3 mx-2 mb-2" id="car" style={{width: "35vh"}}>
                                <div className="card-body">
                                    <div>
                                        <Slide>
                                            {c[6].map((d) => {
                                                return(
                                                    <img key={d[0]} className="card-img-top" src={"https://edusphere.co.in/static/"+d[1]} alt="Card" style={{width:"100%", height: "25vh"}} onClick={(e) => {window.localStorage.getItem("role") === "user" ? nav("/proddetails", {state: {data: c, num: num1, name: uname}}) : window.localStorage.getItem("role") === "retailer" ? nav("/proddetails", {state: {data: c, num: num, name: rname}}) : <></>}}></img>
                                                )
                                            })}
                                        </Slide>
                                        <div>
                                            <p>{c[7][0][2]}</p>
                                            <p>Rs.{c[0] - (c[0] * (c[1]/100))} <del>Rs.{c[0]}</del></p>
                                        </div>
                                    </div>
                                    {role2 === "user" ? 
                                        <div className="mt-2 d-flex justify-content-between">
                                            <button type="button" className="btn btn-warning" onClick={(e) => {Addcart1(c, "cart")}}>Add to Cart</button>
                                            <button type="button" className="btn btn-danger" onClick={(e) => {Addcart1(c, "buy")}}>Buy</button>
                                        </div> : role2 === "retailer" ? 
                                        <div className="mt-2 d-flex justify-content-between">
                                            <button type="button" className="btn btn-warning" onClick={(e) => {Addcart(c, "cart")}}>Add to Cart</button>
                                            <button type="button" className="btn btn-danger" onClick={(e) => {Addcart(c, "buy")}}>Buy</button>
                                        </div> :
                                        <div className="mt-2 d-flex justify-content-center">
                                            <button type="button" className="btn btn-warning" onClick={(e) => {alert("You must Login to View Details")}}>View Details</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Modal show={show1}>
                <ModalHeader closeButton>
                    <ModalTitle>Admin Login</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <form onSubmit={(e) => {handle(e);}}>
                            <label htmlFor="admin_id">Admin Id</label>
                            <input type="text" className="form-control" onChange={(e) => setid(e.target.value)} value={id} placeholder="Enter Admin Id"/>
                            <label htmlFor="admin_password">password</label>
                            <input type="password" className="form-control" onChange={(e) => setpassword(e.target.value)} value={password} placeholder="Enter password"/>
                            <Button type="submit" className="mt-2" id="mod">Submit</Button>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" id="mod" onClick={() => {setshow1(false); setid(""); setpassword("");}}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal show={show2}>
                <ModalHeader closeButton>
                    <ModalTitle>User Login</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <form onSubmit={(e) => {handle1(e);}}>
                            <label htmlFor="user_id">User Id</label>
                            <input type="text" className="form-control" onChange={(e) => setid(e.target.value)} value={id} placeholder="Enter User Id"/>
                            <label htmlFor="user_password">password</label>
                            <input type="password" className="form-control" onChange={(e) => setpassword(e.target.value)} value={password} placeholder="Enter password"/>
                            <Button variant="primary" type="submit" className="mt-2" id="mod"> Submit </Button>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" type="submit" id="mod" onClick={() => {setshow2(false); setid(""); setpassword("");}}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal show={show3}>
                <ModalHeader closeButton >
                    <ModalTitle>Retailer Login</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <form onSubmit={(e) => {handle2(e);}}>
                            <label htmlFor="retailer_id">Retailer Id</label>
                            <input type="text" className="form-control" onChange={(e) => setid(e.target.value)} value={id} placeholder="Enter Retailer Id"/>
                            <label htmlFor="retailer_password">password</label>
                            <input type="password" className="form-control" onChange={(e) => setpassword(e.target.value)} value={password} placeholder="Enter password"/>
                            <Button variant="primary" type="submit" className="mt-2" id="mod">Submit</Button>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="primary" type="submit" id="mod" onClick={() => {setshow3(false); setid(""); setpassword("");}}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal show={show4}>
            <ModalHeader closeButton>
                <ModalTitle>New User Signup</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div>
                <form
                    onSubmit={(e) => {
                    handle3(e);
                    }}
                >
                    <label htmlFor="User_name">User Name</label>
                    <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setname(e.target.value)}
                    value={name}
                    placeholder="Enter name"
                    />
                    <label htmlFor="email">Email</label>
                    <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setemail(e.target.value)}
                    value={email}
                    placeholder="Enter email"
                    />
                    <label htmlFor="mobile">Mobile</label>
                    <input
                    type="text" maxLength={10}
                    className="form-control"
                    onChange={(e) => setmobile(e.target.value)}
                    value={mobile}
                    placeholder="Enter Mobile"
                    />
                    <label htmlFor="User_password">Password</label>
                    <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setpassword(e.target.value)}
                    value={password}
                    placeholder="Enter password"
                    />
                    <Button variant="primary" type="submit" className="mt-2" id="mod">
                    Submit
                    </Button>
                </form>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                variant="primary"
                type="submit" id="mod"
                onClick={() => {
                    setshow4(false);
                }}
                >
                Cancel
                </Button>
            </ModalFooter>
            </Modal>
            <Modal show={show5}>
            <ModalHeader closeButton>
                <ModalTitle>New Retailer Signup</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div>
                <form
                    onSubmit={(e) => {
                    handle4(e);
                    }}
                >
                    <label htmlFor="Retailer_name">Retailer Name</label>
                    <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setname(e.target.value)}
                    value={name}
                    placeholder="Enter name"
                    />
                    <label htmlFor="email">Email</label>
                    <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setemail(e.target.value)}
                    value={email}
                    placeholder="Enter email"
                    />
                    <label htmlFor="mobile">Mobile</label>
                    <input
                    type="text" maxLength={10}
                    className="form-control"
                    onChange={(e) => setmobile(e.target.value)}
                    value={mobile}
                    placeholder="Enter Mobile"
                    />
                    <label htmlFor="Retailer_password">Password</label>
                    <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setpassword(e.target.value)}
                    value={password}
                    placeholder="Enter password"
                    />
                    <label htmlFor="Retailer_id">Discount</label>
                    <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setdiscount(e.target.value)}
                    value={discount}
                    placeholder="Enter Retailer Discount"
                    />
                    <Button variant="primary" type="submit" className="mt-2" id="mod">
                    Submit
                    </Button>
                </form>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                variant="primary"
                type="submit" id="mod"
                onClick={() => {
                    setshow5(false);
                }}
                >
                Cancel
                </Button>
            </ModalFooter>
            </Modal>
        </div>
    )
}
 
export default Homepage;