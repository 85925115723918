import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import "./Style.css";
import ReactToPrint from "react-to-print";

const Qrdetails = () => {
    const role = window.localStorage.getItem("role");
    let nav = useNavigate();
    let location = useLocation();
    let con = 0;
    let vall = "";
    let pr = "";
    const [show1, setshow1] = useState(false);
    const [s, sets] = useState("");
    const data = location.state.prod;
    const [warrent, setwarrent] = useState();
    useEffect(() => {
        if(role === "admin") {
            axios.post("https://edusphere.co.in/bed/viewproductwarrenty").then((res) => {
                setwarrent(res.data);
            });
        }
        else {
            nav("/");
        }
    },[]);
    const handle = (e) => {
        sets(e);
        setshow1(true);
    };

    return ( 
        <div className="bgg" style={{minWidth: "100%"}}>
            <Navbar className="navi"> 
                <div className="comname">Hari Beds</div>
                <div style={{width: "10vh"}}></div>
                <Nav className="navbar">
                    <button type="button" className="btn me-2 mt-2" id="bot">Admin</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/adminpage")}}>Home</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/admin")}}>Add Products</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/viewprod")}}>View Products</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/adminorder", {state: {status: location.state.status, val: location.state.det[1]}})}}>Back</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("adminid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                </Nav>
            </Navbar>
            <div className="row">
                <div className="col-sm-8">
                    <table className="table" id="tan">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Bill Number</th>
                                <th>QR Name</th>
                                <th>Scan QR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {warrent&& warrent.map((c) => {
                                return(c[1] === location.state.det[1] && c[5] === data[3] ? <>
                                    <tr>
                                        <td>{con += 1}</td>
                                        <td>{c[1]}</td>
                                        <td>{c[4]}</td>
                                        <td><button type="button" className="btn" id="bot" onClick={(e) => {handle(c[4])}}>View QR</button></td>
                                    </tr>
                                </> : <></>)
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="col-sm mt-2">
                    <div className="card mx-2" id="car">
                        <div className="card-body">
                            <h5>Product Details</h5>
                            <div className="mt-2">
                                <table className="table" id="tan">
                                    <tbody>
                                        <tr>
                                            <td>{data[7][0][2]}</td>
                                        </tr>
                                        <tr>
                                            <td>Color</td>
                                            <td>{data[9][0][1]}</td>
                                        </tr>
                                        <tr>
                                            <td>Breadth</td>
                                            <td>{data[8][0][0]}</td>
                                        </tr>
                                        <tr>
                                            <td>Depth</td>
                                            <td>{data[8][0][1]}</td>
                                        </tr>
                                        <tr>
                                            <td>Length</td>
                                            <td>{data[8][0][2]}</td>
                                        </tr>
                                        <tr>
                                            <td>Quantity</td>
                                            <td>{location.state.det[3]}</td>
                                        </tr>
                                        <tr>
                                            <td>Price</td>
                                            <td><b>{location.state.det[4]}</b>({data[1]}% off)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-2">
                                <ReactToPrint 
                                    trigger={() => {return <Button type="submit" className="btn" id="bot" style={{width: "100%"}}>Print</Button>}}
                                    content={() => pr} 
                                    pageStyle="print" 
                                />
                            </div>
                        </div>
                    </div>
                    <div id="qrp" ref={(el) => (pr=el)}>
                        {warrent&& warrent.map((c) => {
                            return(c[1] === location.state.det[1] && c[5] === data[3] ? <div className="mt-5">
                                <h3>Hari Beds</h3>
                                <h6>{data[7][0][2]}</h6>
                                <p><span>Size : {data[8][0][2]}x{data[8][0][0]}x{data[8][0][1]}&#8221;</span><br/>
                                    <span>Purchased on - {String(c[2]).slice(5, 16)}</span><br/><span>Warrenty ends on - {String(c[3]).slice(5, 16)}</span>
                                </p>
                                <img src={"https://edusphere.co.in/static/qr/"+c[4]} alt="QR" style={{width:"100px", height: "100px"}}></img>
                                <div>_______________________</div>
                            </div> : <></>)
                        })}
                    </div>
                </div>
            </div>
            <Modal show={show1}>
                <ModalHeader closeButton>
                    <ModalTitle>Scan QR - {s}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className="text-center" id="qrimg" ref={(el) =>(vall=el)}>
                        <img src={"https://edusphere.co.in/static/qr/"+s} alt="QR" style={{width:"75%", height: "50vh"}}></img>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <ReactToPrint 
                        trigger={() => {return <Button type="submit" id="mod">Print</Button>}}
                        content={() => vall} 
                        pageStyle="print" 
                    />
                    <Button type="submit" id="mod" onClick={() => {setshow1(false);}}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
 
export default Qrdetails;