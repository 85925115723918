import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import "./Style.css";

const Statusdetails = () => {
    const [purchase, setpurchase] = useState();
    let nav = useNavigate();
    let location = useLocation();
    useEffect(() => {
        axios.post("https://edusphere.co.in/bed/viewpurchase").then((res) => {
            setpurchase(res.data);
        });
    },[]);
    return ( 
        <div className="bgg" style={{minWidth: "100%"}}>
            <Navbar className="navi"> 
                <div className="comname" style={{fontFamily: 'Autour One', fontSize: '32px', fontStyle: "oblique", fontWeight: "bold"}}>Hari Beds</div>
                <div style={{width: "10vh"}}></div>
                <Nav className="navbar">
                    <button type="button" className="btn me-2 mt-2" id="bot">Admin</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/adminpage")}}>Home</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/admin")}}>Add Products</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {nav("/viewprod")}}>View Products</button>
                    <button type="button" className="btn me-2 mt-2" id="bot" onClick={(e) => {window.localStorage.removeItem("adminid"); window.localStorage.setItem("role", "guest"); nav("/")}}>Logout</button>
                </Nav>
            </Navbar>
            <div className="mt-2">
                <table className="table" id="tan">
                    <thead>
                        <tr>
                            <th>Bill Number</th>
                            <th>Ordered by</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Verify</th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchase&& purchase.map((c) => {
                            return(location.state.status === c[3] ? <>
                                <tr>
                                    <td>{c[0]}</td>
                                    <td>{c[1].split("")[0] === "u" ? "User - " + c[1] : c[1].split("")[0] === "r" ? "Retailer - " + c[1] : ""}</td>
                                    <td>{c[2]}</td>
                                    <td>{c[3]}</td>
                                    <td><button type="button" className="btn" id="bot" onClick={(e) => {nav("/adminorder", {state: {status: location.state.status, val: c[0]}})}}>View Details</button></td>
                                </tr>
                            </> : <></>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
 
export default Statusdetails;